<!-- //新的朋友模板 -->
<template>
  <div>
    <div class="ntMain__head flexbox"><span class="back" @click="$router.go(-1)"><i class="iconfont icon-arrL"></i></span>
    <h2 class="hdtit flex1">新的朋友</h2><span class="link" @click="isShowSearchLayer=true"><i class="iconfont icon-addfriends fs-18"></i></span>
     </div>
     <div class="ntMain__cont flex1 flexbox flex-col">
       <v3-scroll>
        <div class="nt__padCnt sm">
         <div class="nt-cellList nt-newFriends flexbox flex-col">
        <div class="item flexbox flex-alignc vui__hairline-bottom"><div class="img"><img src="@assets/img/uimg/img-avatar04.jpg" /></div>
            <div class="info flex1"><h2 class="title clamp1">Fly</h2><p class="desc clamp1">一起交个朋友。</p></div><span class="vui__btn vui__btn-primary accept">接受</span>
        </div>
        <div class="item flexbox flex-alignc vui__hairline-bottom"><div class="img"><img src="@assets/img/uimg/img-avatar09.jpg" /></div>
            <div class="info flex1"><h2 class="title clamp1">一起2021</h2><p class="desc clamp1">让无聊的日子随风而去~~</p></div> <span class="vui__btn vui__btn-primary isadd">已添加</span>
        </div>
        <div class="item flexbox flex-alignc vui__hairline-bottom"> <div class="img"><img src="@assets/img/uimg/img-avatar10.jpg" /></div>
            <div class="info flex1"><h2 class="title clamp1">杜小丽</h2><p class="desc clamp1">帅哥，能认识下嘛！</p></div> <span class="vui__btn vui__btn-primary isadd">已添加</span>
        </div>
        <div class="item flexbox flex-alignc vui__hairline-bottom"> <div class="img"><img src="@assets/img/uimg/img-avatar15.jpg" /></div>
            <div class="info flex1"><h2 class="title clamp1">佳美</h2><p class="desc clamp1">佳美申请了好友</p></div><span class="vui__btn vui__btn-primary accept">接受</span>
        </div>
        <div class="item flexbox flex-alignc vui__hairline-bottom"><div class="img"><img src="@assets/img/uimg/img-avatar07.jpg" /></div>
            <div class="info flex1"><h2 class="title clamp1">和服少女</h2><p class="desc clamp1">你好，同意一下。</p></div><span class="vui__btn vui__btn-primary isadd">已添加</span>
        </div>
        <div class="item flexbox flex-alignc"> <div class="img"><img src="@assets/img/uimg/img-avatar05.jpg" /></div>
            <div class="info flex1"><h2 class="title clamp1">Flower</h2><p class="desc clamp1">通过一下，一起交流下摄影技术。</p></div><span class="vui__btn vui__btn-primary isadd">已添加</span>
        </div>
          </div>
          </div>
       </v3-scroll>
     </div>
     <v3-layer v-model="isShowSearchLayer"
      title="<i class='iconfont icon-addfriends'></i> 添加朋友" layerStyle="background:#f9f9f9" :area="['450px', '350px']" opacity="0" xclose
     >
        <div class="flexbox flex-col">
         <div style="margin-top:50px;padding:15px 70px;">
         <input class="flex1 align-c" style="background:#fff;border:0;border-radius:2px;box-shadow:0 1px 2px #d2d2d2;padding:10px;width:100%;" placeholder="微信号/手机号" />
            <p class="align-c pad10 fs-12 c-999">我的微信号：xy190310</p>
             </div>
          <div class="mt-30 vui__btn vui__btn-primary" style="margin:0 auto;height:32px;width:150px;">搜索</div>
         </div>
     </v3-layer>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
export default {
    components: {},
    setup() {
      const data = reactive({isShowSearchLayer: false,
     })
      return {...toRefs(data),
     }
  }
}
</script>
